form {
  max-width: 800px;
  margin: 80px auto;
  padding: 40px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h1 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

input[type="text"],
input[type="file"],
select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

label {
  display: block;
  font-weight: bold;
  margin-bottom: 15px;
  color: #555;
}


button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3;
}


button[type="reset"] {
  background-color: #6c757d;
}

button[type="reset"]:hover {
  background-color: #5a6268;
}

.file-upload-container {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

.file-upload-box {
  width: 150px;
  height: 100px;
  border: 2px dashed #d3c3f4;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  flex-direction: column; 
}

.upload-icon {
  font-size: 24px;
  color: #000;
}

.wrapper-change-foto {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upload-change-foto {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 5px;
}

.preview-container {
  margin-top: 10px;
}

.preview-image {
  width: 100%;
  height: auto;
  max-width: 150px;
  border-radius: 5px;
}


.file-upload-text {
  font-size: 14px;
  color: #007bff;
  cursor: pointer;
  margin-top: 5px;
  text-align: center; 
}


.tox-tinymce-aux {
  display: none !important;
}

.form-group {
  margin-bottom: 30px;
}

button {
  margin-left: 0px;
  background-color: #007bff; 
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.tox-edit-area iframe {
  width: 100%;
  height: 300px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.add-category{
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}