.about-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  text-align: center;
  background-color: #ffff;
  padding: 20px;
}

.logo-container {
  margin-bottom: 20px; 
  display: flex;
  justify-content: center;
}

.logo {
  width: 50px; 
  height: auto;
  margin-bottom: 10px;
}


.highlight {
  color: red;
}


.text-container {
  max-width: 600px;
}

.text-container p {
  font-size: 1.2em;
  color: #333;
  line-height: 1.6;
  margin-bottom: 20px;
}
