.article-table-container {
  width: 75%; 
  margin: 60px auto; 
  padding-left: 10px;
  overflow-x: hidden; 
}

.article-table {
  width: 100%; 
  border-collapse: collapse; 
  margin: 30px 0; 
  font-size: 15px; 
}

.article-table th,
.article-table td {
  border: 1px solid #ddd; 
  padding: 10px; 
  text-align: left; 
}

.article-table th {
  background-color: #f2f2f2; 
  font-weight: bold; 
}

.article-table td img {
  width: 100px; 
  height: auto; 
  border-radius: 8px;
}

.pagination {
  display: flex;
  justify-content: center; 
  margin: 20px 0; 
}

.page-item {
  margin: 0 5px; 
}

.page-item button {
  padding: 10px 15px; 
  border: none; 
  border-radius: 5px; 
  background-color: #f1f1f1; 
  cursor: pointer; 
}

.page-item.active button {
  background-color: #4caf50; 
  color: white; 
}

.action-button {
  color: white; 
  border: none; 
  padding: 10px; 
  margin: 3px; 
  cursor: pointer; 
  border-radius: 5px; 
  font-size: 12px; 
}

.action-button.edit {
  background-color: #F7CF52; 
}

.action-button.delete {
  background-color: red; 
}

.action-button.headline {
  background-color: #008CBA; 
}

.action-button.edit:hover {
  background-color: #cccc41; 
}

.action-button.delete:hover {
  background-color: #ff4d4d; 
}

.action-button.headline:hover {
  background-color: #138399; 
}

@media (max-width: 768px) {
  .article-table td img {
    width: 70px;
  }
  
  .pagination .page-item button {
    padding: 8px 12px;
  }
}

h1 {
  text-align: center; 
  margin-bottom: 20px;
}
