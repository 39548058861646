.top-tag-link {
  font-family: Arial, sans-serif;
  margin: 10px;
  margin-left: 33px;
}

.top-tag-header {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-bottom: 15px;
}

.top-tag-header::before {
  content: '';
  display: inline-block;
  width: 6px;
  height: 24px;
  background-color: rgb(204, 41, 41);
  margin-right: 10px;
}

.top-tag-link-list {
  list-style-type: none;
  padding-left: 14px;
}

.card-tag-link {
  margin-bottom: 10px;
}

.link-tag {
  font-size: 16px;
  color: #333;
  text-decoration: none;
}

.link-tag a {
  color: #333;
  text-decoration: none;
  transition: color 0.2s;
}

.link-tag a:hover {
  color: #e74c3c;
  text-decoration: underline;
}

.link-tag span {
  display: inline-block;
  margin-bottom: 5px;
}
