.article-detail {
  max-width: 800px;
  padding: 60px 20%;
}

.breadcrumb {
  margin: 20px 0;
  font-size: 16px;
  color: #555;
  display: flex; 
  align-items: center;
}

.breadcrumb-link {
  text-decoration: none;
  color: rgb(204, 41, 41); 
  margin-right: 5px;
}

.breadcrumb-link:hover {
  text-decoration: underline;
}

.breadcrumb span {
  color: #555;
}

h1 {
  font-size: 28px;
  margin-bottom: 20px;
  text-align: left; 
}

.footer-detail-artikel {
  display: flex; 
  justify-content: space-between; 
  align-items: center; 
  margin-bottom: 20px;
  font-size: 14px;
  color: #777;
}

.image-wrapper {
  text-align: center;
  margin-bottom: 20px;
}

.article-image-full {
  width: 100%; 
  height: auto; 
  object-fit: contain;
  border-radius: 8px;
}

.article-body {
  margin-bottom: 16px;
  font-size: 18px;
  line-height: 1.6;
  text-align: justify;
}

.image-wrapper, .video-wrapper {
  text-align: center;
  margin-bottom: 20px;
}

.video-responsive {
  position: relative;
  padding-bottom: 56.25%; 
  height: 0;
  overflow: hidden;
  max-width: 100%;
  background-color: transparent; 
}

.video-responsive iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.article-body-container {
  margin-top: 20px;
}

.comments-section h3 {
  font-size: 24px;
  margin-bottom: 10px;
}

.comment {
  width: 100%;
  padding: 15px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 15px;
  box-sizing: border-box; 
}

.comment p {
  margin: 5px 0 0;
  font-size: 14px;
  color: #555;
}

.comment-form h3 {
  margin-bottom: 15px;
  font-size: 20px;
}

.comment-form label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.comment-form input,
.comment-form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  box-sizing: border-box; 
}

.comment-form textarea {
  resize: vertical; 
}

.comment-form button {
  background-color: rgb(204, 41, 41);
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.like-container {
  display: flex;
  justify-content: flex-end; 
  align-items: center;
  gap: 8px;
  margin-top: 20px;
}

.like-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: rgb(204, 41, 41);
  padding: 8px 12px;
  border-radius: 4px;
  color: white;
  font-weight: bold;
  transition: background-color 0.3s;
}

.like-icon {
  margin-left: 5px; 
}

.like-count {
  font-size: 16px;
  color: #777;
  font-weight: bold;
}

.social-share-buttons {
  display: flex;
  gap: 10px; 
  margin-top: 30px; 
}

.social-icon {
  display: inline-block;
  width: 40px; 
  height: 40px; 
  border-radius: 50%;
  background-color: #f1f1f1; 
  color: #333; 
  text-align: center;
  line-height: 40px; 
  transition: background-color 0.3s;
}

.social-icon:hover {
  background-color: #ddd; 
}

.related-articles {
  padding: 20px;
  background-color: white;
}

.related-articles h3 {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
}

.related-articles h3::before {
  content: '';
  width: 6px;
  height: 24px;
  background-color: rgb(204, 41, 41);
  margin-right: 10px;
}

.related-article-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); 
  gap: 20px;
}

.related-article-card {
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
  transition: box-shadow 0.3s ease;
}

.related-article-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.related-article-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.related-article-text {
  padding: 10px;
}

.related-article-title {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin-bottom: 5px;
  display: block;
}

.related-article-meta {
  font-size: 12px;
  color: #666;
}

.related-article-link {
  text-decoration: none;
  color: black;
}

.related-article-link:hover {
  text-decoration: underline; 
}

.logo-thumbnail{
  position: absolute;
  top: -11%;
  left: 1%;
  background: url(https://www.anjaniwahyudi.com/static/media/logo.e8d9a24b8b0057c8d51c.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  opacity: 0.5;
  width: 30%;
  height: 30%;
}

@media (max-width: 1200px) {
  .related-article-grid {
    grid-template-columns: repeat(3, 1fr); 
  }

  .article-detail {
    max-width: 800px;
    padding: 100px 39PX;
  }
}

@media (max-width: 800px) {
  .related-article-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .like-container {
    justify-content: flex-end; 
  }
  .like-button {
    width: auto;
    padding: 5px 10px;
  }
  .like-count {
    font-size: 14px;
  }
}

@media (max-width: 500px) {
  .related-article-grid {
    grid-template-columns: 1fr; 
  }
}
