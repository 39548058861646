.search-results-page {
  margin: 10px 10%;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

h1 {
  font-size: 28px;
  color: #333;
  margin-bottom: 20px;
}

p {
  font-size: 18px;
  color: black;
  margin-bottom: 20px;
}

ul {
  list-style-type: none;
  padding: 0;
}

.result-item {
  margin-bottom: 15px;
  padding: 15px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #fff;
  transition: box-shadow 0.3s;
}

.result-item:hover {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.result-link {
  text-decoration: none;
  color: #12559c;
  font-size: 18px;
  font-weight: bold;
}

.result-link:hover {
  color: #0056b3;
}

.result-meta {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  font-size: 14px;
  color: #777;
}

.media-partner {
  font-weight: bold;
}

.result-date {
  color: #555;
}
