.pedoman-siber-container {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
  }
  
  .pedoman-siber-container h1 {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .pedoman-siber-container h2 {
    font-size: 1.5rem;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .pedoman-siber-container p {
    margin-bottom: 15px;
  }
  