.popular-article-container {
  padding: 75px 128px;
}

.header-ar-popular {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
}

.header-ar-popular::before {
  content: '';
  width: 6px;
  height: 24px;
  background-color: rgb(204, 41, 41);
  margin-right: 10px;
}

.popular-article-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); 
  gap: 20px; 
}

.popular-article-card {
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
  transition: box-shadow 0.3s ease;
}

.popular-article-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.popular-article-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.popular-article-text {
  padding: 10px;
}

.popular-article-title {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin-bottom: 5px;
  display: block;
}

.popular-article-meta {
  font-size: 12px;
  color: #666;
}

.link {
  color: #333;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

.error {
  color:rgb(204, 41, 41);
}

@media (max-width: 768px) {
  .popular-article-container {
    padding: 33% 8% 3% 8%;
  }
}

@media (max-width: 1200px) {
  .popular-article-grid {
    grid-template-columns: repeat(3, 1fr); 
  }
}

@media (max-width: 800px) {
  .popular-article-grid {
    grid-template-columns: repeat(2, 1fr); 
  }
}

@media (max-width: 500px) {
  .popular-article-grid {
    grid-template-columns: 1fr; 
  }
}
