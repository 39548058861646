.social-share-buttons {
    display: flex;
    gap: 10px;
    margin-top: 20px;
  }
  
  .social-icon {
    display: inline-block;
    width: 40px; 
    height: 40px;
    border-radius: 50%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
  }
  
  .social-icon.twitter {
    background-color: #1DA1F2; 
  }
  
  .social-icon.whatsapp {
    background-color: #25D366; 
  }
  
  .social-icon svg {
    width: 20px; 
    height: 20px; 
  }
  