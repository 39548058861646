@import "~slick-carousel/slick/slick-theme.css";
@import "~slick-carousel/slick/slick.css";

.slider-container {
  width: 100%;
  padding: 80px 0px 10px 0px;
  position: relative;
}

.item-slider {
  position: relative;
}

.slider-image {
  width: 100%;
  height: 350px;
  border-radius: 0px;
  object-fit: cover;
}

.slider-title {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6); 
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  padding: 10px;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.slick-prev, .slick-next {
  width: 80px;
  height: 80px;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  display: flex;
  align-items: center;
}

.slick-prev::before, .slick-next::before {
  font-size: 30px;
  color: white;
}

.slick-dots {
  display: none;
}

@media (max-width: 768px) {
  .slider-image {
    max-height: 250px;
    border-radius: 0px;
    object-fit: cover;
  }

  .slider-container {
    max-height: 382px;
    padding: 120px 0px 0px 0px;
  }

  .slick-next {
    right: -10px !important;
  }

  .slick-prev {
    left: -10px !important;
  }
  
  .slider-title {
    font-size: 16px;
    padding: 8px;
  }
}

