.search-container {
    width: 80%;
    padding: 30px 10% 0px 10%;
}

.input-wrapper {
    display: flex;
    align-items: center;
    position: relative;
    padding-top: 60px;
}

.search-input {
    width: 100%;
    padding: 12px 0; 
    padding-left: 16px;
    padding-right: 50px; 
    border: none;
    border-radius: 50px;
    font-size: 16px;
    outline: none;
    background-color: #f5f5f5;
}

.search-icon {
    position: absolute;
    right: 15px;
    top: 62%; 
    transform: translateY(-25%); 
    font-size: 18px;
    color: #888;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
}

.search-icon:hover {
    color: #333;
}

.popular-searches {
    margin-top: 20px;
}

.popular-searches h3 {
    font-size: 18px;
    color: #333;
    margin-bottom: 10px;
    font-weight: bold;
    display: flex;
    align-items: center;
}

.popular-searches h3::before {
    content: '';
    display: inline-block;
    width: 4px;
    height: 20px;
    background-color: rgb(204, 41, 41);
    margin-right: 10px;
}

.search-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    flex-direction: column;
}

.search-button {
    padding: 5px 15px;
    background-color: #eef1f5;
    color: #2e67a1;
    border: none;
    border-radius: 15px;
    font-size: 14px;
    cursor: pointer;
    white-space: nowrap;
}

.search-button:hover {
    background-color: #d6dffe;
}

@media (max-width: 768px) {
    .search-container {
        max-width: 90%; 
        margin: 0px; 
    }

    .search-input {
        padding: 10px 0;
        padding-left: 12px;
        padding-right: 40px;
        font-size: 14px; 
    }

    .search-icon {
        font-size: 16px; 
        right: 27px; 
        height: 20px;
        width: 20px;
        position: absolute;
        top: 72%;
    }

    .popular-searches {
        margin: 0px 0px; 
        display: flex;
        flex-direction: column; 
        align-items: flex-start; 
        width: 100%; 
    }

    .popular-searches h3 {
        font-size: 16px; 
        margin-bottom: 10px; 
    }

    .search-buttons {
        display: flex;
        flex-wrap: wrap; 
        justify-content: flex-start; 
        width: 100%; 
        padding: 0; 
        margin: 0; 
    }

    .search-button {
        padding: 4px 5px;
        background-color: #eef1f5;
        color: #2e67a1;
        font-size: 12px; 
        cursor: pointer;
        white-space: normal; 
        max-width: 100%; 
        word-wrap: break-word; 
    }    

    .search-button:hover {
        background-color: #d6dffe; 
    }

    
.input-wrapper {
    display: flex;
    align-items: center;
    position: relative;
    padding: 95px 20px 0px 40px;
}
}
