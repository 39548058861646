body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; 
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #ffff;
  position: fixed; /* Menggunakan fixed supaya selalu berada di atas */
  top: 0; 
  width: 100%; 
  z-index: 1000;
  transition: top 0.3s ease; /* Untuk transisi yang halus jika ada perubahan posisi */
}

.navbar-bottom {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  position: fixed; 
  background-color: rgb(204, 41, 41);
  height: 60px;
}

.wraper-navbar-bottom {
  width: 100%;
  background-color: rgb(204, 41, 41); 
  height: 60px;
  z-index: 9999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
}

.nav-left, .nav-right {
  display: flex;
  align-items: center;
}

.nav-left i, .nav-right i {
  font-size: 1.5rem;
  margin: 0 10px;
  cursor: pointer;
}

.nav-logo {
  display: flex;
  justify-content: flex-start;
}

.nav-logo img {
  height: 25px;
  margin-left: 20px;
  width: 200px; 
}

.menu-list {
  display: flex;
  list-style: none;
  justify-content: center;
  align-items: center;
  margin-right: 100px;
}

.menu-list li {
  margin: 0 15px 0 20px;
}

.menu-list li a {
  text-decoration: none;
  color: white;
  font-size: 19px;
}

.menu-list li a:hover {
  color: black;
}

/* Mobile View */
@media (max-width: 768px) {
  .menu-list {
    display: none; 
  }

  .navbar {
    flex-direction: column; 
    align-items: flex-start; 
    padding: 10px; 
    position: fixed; /* Fixed position for mobile */
    top: 0; /* Ensure it's at the top */
    z-index: 1000; /* Ensure navbar stays on top */
    width: 100%;
    background-color: #fff; /* Make sure the background stays consistent */
  }

  .nav-logo {
    margin-left: 0; 
  }

  .nav-right {
    width: 100%;
    justify-content: flex-end; 
  }

  .nav-left {
    width: 100%;
    justify-content: space-between; 
  }

  .menu-wraper.mobile-show {
    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.8);
    position: absolute;
    top: 60px;
    right: 1%;
    width: 100%;
    padding: 10px 0;
  }

  .menu-list {
    display: flex;
    overflow-x: scroll;
    background-color: rgb(204, 41, 41);
    width: 100%;
    padding: 10px 5px 10px 11px;
  }

  .menu-list.mobile-show li {
    margin: 10px 0;
    text-align: center;
  }

  .menu-list li a {
    color: white;
  }

  .wraperDesktop{
    z-index: 99;
    left: 0;
    position: absolute;
    top: 90%;
    width: 96%;
  }

  .wraper-navbar-bottom {
    width: 100%;
    background-color: rgb(204, 41, 41); 
    height: 60px;
    z-index: 9999;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
  }
}
