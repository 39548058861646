.footer {
    background-color:rgb(204, 41, 41);
    color: white;
    text-align: center;
    padding: 10px;
    bottom: 0;
    width: 100%;
}

.social-icons {
    margin-top: 10px;
    display: flex;
    justify-content: center;
}

.social-icons a {
    color: white;
    margin: 0 10px;
    text-decoration: none;
    font-size: 24px;
}

@media (max-width: 600px) {
    .footer {
        padding: 15px;
        font-size: 14px;
        width: 92%;
    }

    .social-icons a {
        font-size: 20px; 
        margin: 0 5px;
    }
}

