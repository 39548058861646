.top-tag-articles {
  font-family: Arial, sans-serif;
  padding: 15px;
  background-color: #fff;
}

.top-tag-header {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-bottom: 15px;
}

.top-tag-header::before {
  content: '';
  display: inline-block;
  width: 6px;
  height: 24px;
  background-color: rgb(204, 41, 41);
  margin-right: 10px;
}

.top-info {
  font-size: 14px;
  color: #555;
  margin-bottom: 10px;
  line-height: 1.5;
  font-style: italic;
}

.top-tag-articles-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.card-tag {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.article-number {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: rgb(204, 41, 41);
  font-size: 14px;
}

.article-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.article-title {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin-bottom: 2px;
  text-decoration: none;
}

.article-title:hover {
  color: #555;
}

.article-meta {
  font-size: 15px;
  color: rgb(204, 41, 41);
  margin-top: 3px;
}
