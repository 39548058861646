.contact-container {
  background-color: #ffff;
  color: black;
  padding: 40px;
  max-width: 600px;
  margin: auto;
}

h1 {
  text-align: flex;
  margin-bottom: 20px;
}

.contact-form {
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 5px;
  font-weight: bold;
}

input, select, textarea {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 20px; 
}

input:focus, select:focus, textarea:focus {
  outline: none;
  border: 2px solid #fff; 
}

textarea {
  resize: none; 
  height: 100px;
}

.submit-button {
  background-color: rgb(204, 41, 41);
  color: white;
  padding: 12px;
  border: none;
  border-radius: 4px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: darkred; 
}
