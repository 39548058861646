.dashboard-layout {
  display: flex;
}

.sidebar-container {
  padding: 30px;
  width: 200px; 
  height: 100vh; 
  background-color: #ffff; 
  position: fixed; 
  top: 0px; 
  left: 0;
  z-index: 999;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
}

.logo-container {
  text-align: center;
  margin-bottom: 50px;
}

.logo {
  max-width: 100%; 
  height: auto;
}

.list-sidebar {
  list-style: none;
  margin: 15px 0;
}

.list-sidebar a {
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 15px;
  border-radius: 5px;
  color: #333;
  transition: background-color 0.3s;
}

.list-sidebar a:hover {
  background-color: rgb(204, 41, 41); 
  color: white; 
}

.list-sidebar a span {
  margin-left: 10px;
}

.header-container {
  width: 79%; 
  height: 60px;
  background-color:rgb(204, 41, 41);
  display: flex;
  align-items: center;
  padding: 0 20px;
  position: fixed;
  top: 0;
  left: 260px; 
  z-index: 1000;
  justify-content: space-between; 
}

.header-right {
  margin-left: auto; 
}

.logout-link {
  text-decoration: none;
}

.logout-icon {
  color: white;
  font-size: 26px;
  cursor: pointer;
  transition: color 0.3s;
}

.logout-icon:hover {
  color: #f4f4f4;
}

.dropdown-menu {
  position: absolute;
  top: 50px; /* Adjust based on header height */
  right: 20px; /* Align with the icon */
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding: 10px;
}

.dropdown-item {
  display: block;
  padding: 10px;
  color: #333;
  text-decoration: none;
  transition: background-color 0.2s;
}

.dropdown-item:hover {
  background-color: rgb(204, 41, 41);
  color: white;
}

